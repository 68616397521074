import React from 'react'
import { graphql, Link } from 'gatsby'
import {
    mapEdgesToNodes,
    filterOutDocsWithoutSlugs,
    filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { ContactSection } from '../components/sections/contact-section'
import Img from 'gatsby-image'

export const query = graphql`
  query PartnersPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
	},
	page: sanityPlainPage(_id: { eq: "partnersPage" }) {
		id
        slug {
          current
        }
        showBanner
        seoTitle
        seoKeywords
        seoDescription
        hideOverlay
        bannerTitle
        bannerSubTitle
        bannerImage {
          asset {
            url
            fluid {
				...GatsbySanityImageFluid
			}
          }
        }
        previewImage {
            asset {
              url
              fluid {
                  ...GatsbySanityImageFluid
              }
            }
          }
        bannerCta {
          label
          url
        }
	}
  }
`

const PartnersPage = ({ data = {}, errors }) => {

    if (errors) {
        return (
            <Layout page={data.page || {}} >
                <GraphQLErrorList errors={errors} />
            </Layout>
        )
    }

    const site = data.site || {}
    const page = data.page || {}
    const { previewImage = {} } = page
    const { asset = {} } = previewImage || {}
    const { fluid = {} } = asset || {}

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        )
    }

    return (
        <Layout page={data.page} >
            <Container>
                <section className="py-16 px-4 4xl:px-0 bg-white">
                    <div className="max-w-6xl mx-auto px-4">
                        <p className="sm:ml-8 sm:pl-8 italic sm:border-l-4 border-orange-300 text-gray-800 text-lg leading-loose">Bij Angsthelden willen we de hulp bij angst en paniek naar een hoger niveau tillen. Doordat we verschillende aanpakken hebben kunnen we altijd aansluiten bij wat een cliënt nodig heeft. Zo hebben we onder andere een app ontwikkeld voor een Virtual Reality bril waarmee je toegang krijgt tot een grote verzameling angst filmpjes. Zo kunnen we met levensechte spannende situaties oefenen vanuit de veilige praktijkruimte. Om de angstbeleving nog reëler te maken, kun je met een druk op de knop een geluid van de hartslag en ademhaling toevoegen. Als de lading hoog is kun je met een ingebouwde EMDR techniek en een revolutionaire nieuwe techniek de lading snel verlagen. De resultaten die we hier mee boeken zijn verbluffend goed. We willen het liefst zoveel mogelijk mensen helpen maar we kunnen dit niet alleen. Als jij een goede therapeut bent en een drive hebt om mensen van hun angst af te helpen met de nieuwste technieken, willen wij graag met jou samenwerken.</p>
                    </div>
                </section>
                <section className="py-16 px-4 4xl:px-0 bg-gray-100">
                    <div className="md:flex flex-row max-w-6xl mx-auto px-4">
                        <div className="flex-1">
                            <Img className="rounded-lg" fluid={fluid} />
                        </div>
                        <div className="flex-2 mt-16 md:mt-0 md:ml-16 text-gray-800 text-lg leading-loose">
                            <p className>
                                <b>Hoe werkt dit voor jou?</b> Als je belangstelling hebt nodigen wij je uit, samen met andere geïnteresseerde therapeuten voor een bijeenkomst. We leggen dan uit hoe onze app werkt. Als je besluit om partner te worden krijg je van ons een code om in te loggen in onze app waarmee je toegang krijgt tot de bibliotheek met angstvideo’s. Via een link die wij je geven kun je een geschikte Virtual Reality bril aanschaffen en een dag later kun je al aan de slag. Na het tekenen van de overeenkomst krijg je een korte <b>training in onze angst aanpakken</b>. Waarbij je ook gebruik mag maken van onze begeleidende documenten.
                            </p>
                            <p className="mt-4">
                                Op dit moment krijgen we vanuit heel Nederland cliënten, maar het is voor de cliënt natuurlijk prettiger als ze naar een <b>therapeut in de buurt</b> kunnen. Daarom bieden wij ook de mogelijkheid om <b>extra cliënten</b> doorgestuurd te krijgen. Voor het adverteren in jouw regio vragen we een klein bijdrage per cliënt.
                            </p>
                            <p className="mt-4">
                                Gaan we samen voor de goede zaak? Meld je aan via info@angsthelden.com of bel ons op 06-15510015
                            </p>
                            <p className="mt-4 italic text-gray-500 text-base">
                                Voor de beste kwaliteit van Virtual Reality hard- en software, installatie en supportservices werkt Angsthelden samen met partner VR Expert. Neem gerust eens een kijkje op http://www.vr-expert.nl voor meer informatie.
                            </p>
                        </div>
                    </div>
                </section>
                <ContactSection title="Neem contact op" />
            </Container>
        </Layout>
    )
}

export default PartnersPage
